@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer base {
  body {
    @apply bg-gray-100 font-semibold text-gray-800;
  }
}
